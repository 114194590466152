import { config } from '@/config'
import axios from 'axios'
import { useAuthenticationStore, useGroup } from '@/store'
import router from '@/routers'
import { useToast } from 'vue-toastification'
import { ref } from 'vue'

const apiUrl = config.api.url + '/api/v1'

const createAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: apiUrl
  })

  axiosInstance.interceptors.request.use(async config => {
    const authStore = useAuthenticationStore()
    const groupStore = useGroup()
    const accessToken = sessionStorage.getItem('accessToken')
    const databaseName = groupStore.getActiveGroup.databasename
    const companyId = groupStore.getActiveGroup.id

    config.headers['Content-Type'] = 'application/json'
    config.headers.Authorization = `Bearer ${accessToken}`
    config.headers.database =
      databaseName === 'ate_sg3' || databaseName === 'ate_sg34'
        ? databaseName
        : 'ate_sg34'
    config.headers.idempresa = companyId
    config.headers.dialect = 'mysql'

    // Simulando uma demora na rede
    // await new Promise(resolve => setTimeout(resolve, 2000)) // 2 segundos de atraso

    return config
  })

  return axiosInstance
}

export const useApiHandler = () => {
  const loading = ref(false)
  const error = ref(null)
  const axiosInstance = createAxiosInstance()
  const toast = useToast()

  const handleError = (err, { showToast = true, customMessage = null }) => {
    let errorDetails = {
      status: 0,
      message: 'Erro de conexão. Verifique sua internet.',
      data: null,
      originalError: err
    }

    if (err.response) {
      const { status, data } = err.response

      const errorMessages = {
        400: 'Requisição inválida.',
        401: 'Sua sessão expirou, faça login novamente.',
        403: 'Você não tem permissão para acessar este recurso.',
        404: 'Recurso não encontrado.',
        500: 'Erro interno do servidor.',
        502: 'Serviço temporariamente indisponível.',
        503: 'Serviço indisponível.',
        504: 'Tempo de resposta excedido.'
      }

      errorDetails = {
        status,
        message:
          customMessage ||
          data?.message ||
          errorMessages[status] ||
          'Ocorreu um erro na requisição',
        data,
        originalError: err
      }

      console.log('Error details:', errorDetails)
      console.log('Custom message:', customMessage)

      if (showToast) {
        toast.error(errorDetails.message)
      }
    } else if (err.request) {
      errorDetails.message = 'Servidor não respondeu à requisição.'

      if (showToast) {
        toast.error(errorDetails.message)
      }
    } else if (showToast) {
      toast.error(errorDetails.message)
    }

    return errorDetails
  }

  const sendRequest = async (method, url, data = null, options = {}) => {
    const { showToast = true, errorMessage = null } = options
    try {
      loading.value = true
      error.value = null
      const response = await axiosInstance.request({
        method,
        url,
        data
      })
      return {
        success: true,
        data: response.data
      }
    } catch (err) {
      const errorDetails = handleError(err, {
        showToast,
        customMessage: errorMessage
      })
      error.value = errorDetails

      return {
        success: false,
        error: errorDetails
      }
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    error,
    sendRequest
  }
}
export default useApiHandler
