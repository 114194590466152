import { defineStore } from 'pinia'

export const useAuthenticationStore = defineStore({
  id: 'authenticationStore',
  state: () => ({
    accessToken: '',
    refreshToken: '',
    expires: 0,
    user: {},
    userAuthenticated: false,
    session_id: '',
    userId: ''
  }),
  getters: {
    isAuthenticated() {
      return this.userAuthenticated
    },
    getAccessToken() {
      return this.accessToken
    }
  },
  actions: {
    setToken(accessToken) {
      this.accessToken = accessToken
    },
    setRefreshToken(refreshToken) {
      this.refreshToken = refreshToken
    },
    setExpires(expires) {
      this.expires = expires
    },
    setUserData(user) {
      this.user = user
    },
    setSessionId(session_id) {
      this.session_id = session_id
    },
    setUserId(userId) {
      this.userId = userId
    },
    acceptTerms() {
      if (this.user) {
        this.user.aceitoutermos = String(true)
      }
    },
    setUserAuth(data) {
      this.userAuthenticated = data
    },
    logout() {
      this.$reset()
      this.accessToken = ''
      this.refreshToken = ''
      this.user = {}
      this.userAuthenticated = false
      localStorage.clear()
      sessionStorage.clear()
    }
  },
  persist: {
    key: 'authenticationStore',
    storage: localStorage,
    beforeRestore: function (ctx) {
      const storedValue = localStorage.getItem(ctx.store.$id)
      if (storedValue) {
        const parsedValue = JSON.parse(storedValue)
        if (!parsedValue.accessToken && !parsedValue.userAuthenticated) {
          localStorage.clear()
        }
      }
    }
  }
})
