// src/composables/useWebSocket.js
import { onUnmounted, onMounted } from 'vue'
import { websocketService } from '@/services/WebSocketService'

export function useWebSocket(userType, userId, sessionId) {
  onMounted(() => {
    // Inicializa o WebSocket apenas uma vez em toda a aplicação
    websocketService.setupWebSocket(userType, userId, sessionId)
  })

  onUnmounted(() => {
    // Não desconecta ao desmontar os componentes individuais
    // A conexão permanece ativa enquanto a aplicação estiver aberta
  })

  return websocketService
}
