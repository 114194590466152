<template>
  <!-- Teleport para renderizar o modal no body -->
  <teleport to="body">
    <!-- Overlay do modal -->
    <div v-if="show" class="modal-overlay" @click="closeModal">
      <!-- Conteúdo do modal -->
      <div class="modal-content" @click.stop>
        <!-- Cabeçalho do modal -->
        <div class="modal-header">
          <h3>Termos de Uso</h3>
        </div>

        <!-- Corpo do modal -->
        <div class="modal-body">
          <ol type="I">
            <li>
              O uso deste aplicativo ("App") está sujeito aos seguintes termos de
              uso ("Termos"). Ao utilizar o App, você concorda em cumprir esses
              Termos. Se você não concordar com esses Termos, não utilize o App.
            </li>
            <li>
              O App é destinado exclusivamente para uso em segurança do trabalho.
              Não deve ser usado para qualquer outra finalidade.
            </li>
            <li>
              O App é fornecido "como está" e "conforme disponível". Não
              garantimos que o App será ininterrupto ou livre de erros, e não nos
              responsabilizamos por quaisquer danos decorrentes do uso do App.
            </li>
            <li>
              Você é responsável por manter a confidencialidade de sua conta e
              senha, e por todas as atividades que ocorrem sob sua conta.
            </li>
            <li>
              Não devemos ser responsáveis por quaisquer perdas ou danos causados
              por sua falha em proteger a confidencialidade de sua conta e senha.
            </li>
            <li>
              Não devemos ser responsáveis por quaisquer perdas ou danos
              decorrentes do uso não autorizado do seu dispositivo ou conta.
            </li>
            <li>
              Você concorda em notificar-nos imediatamente de qualquer uso não
              autorizado de sua conta ou qualquer outra violação de segurança.
            </li>
            <li>
              Você concorda em não usar o App para qualquer finalidade ilegal ou
              não autorizada.
            </li>
            <li>
              O App pode conter links para sites de terceiros. Esses links são
              fornecidos apenas para sua conveniência e não controlamos o conteúdo
              desses sites. Não nos responsabilizamos por quaisquer danos ou
              prejuízos decorrentes do uso de tais sites.
            </li>
            <li>
              Reservamos o direito de modificar esses Termos a qualquer momento.
              Seus usos contínuos do App após tais modificações constituirão sua
              aceitação de tais modificações.
            </li>
            <li>
              Esses Termos serão regidos e interpretados de acordo com as leis do
              país onde o App está disponível. Qualquer litígio decorrente do uso
              do App será resolvido exclusivamente na justiça desse país.
            </li>
          </ol>
        </div>

        <!-- Rodapé do modal -->
        <div class="modal-footer">
          <button @click="closeModal" class="btn-cancel">
            Fechar
          </button>
          <button @click="acceptModal" class="btn-accept">
            Aceitar
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
const props = defineProps<{ show: boolean }>();
const emit = defineEmits(['close', 'accept']);

const closeModal = () => {
  emit('close');
};

const acceptModal = () => {
  emit('accept');
};
</script>

<style scoped>
/* Overlay do modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fundo escuro semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Garante que o overlay fique acima de outros elementos */
}

/* Conteúdo do modal */
.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  /* Largura máxima do modal */
  max-height: 90vh;
  /* Altura máxima do modal */
  overflow-y: auto;
  /* Adiciona scroll se o conteúdo for muito longo */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  /* Garante que o modal fique acima do overlay */
}

/* Cabeçalho do modal */
.modal-header {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  font-size: 1.25rem;
  font-weight: bold;
}

/* Corpo do modal */
.modal-body {
  padding: 16px;
}

/* Rodapé do modal */
.modal-footer {
  padding: 16px;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

/* Botões */
.btn-cancel {
  background-color: #ef4444;
  /* Vermelho */
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-accept {
  background-color: #3b82f6;
  /* Azul */
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-cancel:hover,
.btn-accept:hover {
  opacity: 0.9;
}
</style>
