import { config } from '@/config'
import axios from 'axios'
import { useAuthenticationStore, useGroup } from '@/store'
import router from '@/routers'
import { useToast } from 'vue-toastification'

const apiUrl = config.api.url + '/api/v1'

const axiosInstance = axios.create({
  baseURL: apiUrl
})

axiosInstance.interceptors.request.use(async config => {
  const authStore = useAuthenticationStore()
  const groupStore = useGroup()
  const accessToken = sessionStorage.getItem('accessToken')
  const databaseName = groupStore.getActiveGroup.databasename
  const companyId = groupStore.getActiveGroup.id

  config.headers['Content-Type'] = 'application/json'
  config.headers.Authorization = `Bearer ${accessToken}`
  config.headers.database =
    databaseName === 'ate_sg3' || databaseName === 'ate_sg34'
      ? databaseName
      : 'ate_sg34'
  config.headers.idempresa = companyId
  config.headers.dialect = 'mysql'

  return config
})

const sendApiRequest = async (method, url, request) => {
  try {
    const response = await axiosInstance.request({
      method,
      url,
      data: request
    })
    return response
  } catch (error) {
    if (error.response && error.response.status === 401) {
      forceLogout()
    }
    throw error
  }
}

function forceLogout() {
  const authStore = useAuthenticationStore()
  authStore.logout()
  const toast = useToast()
  router.push({ name: 'Login' })
  toast.error('Sua sessão expirou, faça login novamente.')
}

function createApiInterface() {
  return {
    get: (url, request) => sendApiRequest('GET', url, request),
    post: (url, request) => sendApiRequest('POST', url, request),
    put: (url, request) => sendApiRequest('PUT', url, request),
    delete: (url, request) => sendApiRequest('DELETE', url, request),
    patch: (url, request) => sendApiRequest('PATCH', url, request)
  }
}

export { createApiInterface as getAPI }
