// src/services/websocketService.js
import { ref, shallowRef } from 'vue'

import { config } from '@/config'

const api_url_ws = config.api.url_ws + '/api/v1'
const api_url = config.api.url + '/api/v1'

// Estado compartilhado
const socket = shallowRef(null)
const connectionStatus = ref('disconnected')
const activeUsers = ref([])
const isAdmin = ref(false)
let heartbeatInterval = null
let reconnectTimeout = null
let wsUrl = ''
let currentUserId = null
let currentSessionId = null
let initialized = false

// Configuração da conexão
const setupWebSocket = (userType, userId, sessionId) => {
  if (initialized && socket.value) {
    console.log('WebSocket já inicializado. Reusando conexão existente.')
    return
  }

  const token = sessionStorage.getItem('accessToken')

  currentUserId = userId
  currentSessionId = sessionId
  isAdmin.value = userType === 'admin'

  console.log('Configurando WebSocket para:', userType, userId, sessionId)

  wsUrl =
    userType === 'admin'
      ? `${api_url_ws}/ws/admin/${userId}?token=${token}`
      : `${api_url_ws}/ws/user/${sessionId}?token=${token}`

  initialized = true
  connect()
}

// Função para iniciar conexão WebSocket
const connect = () => {
  if ((!currentUserId && !currentSessionId) || !wsUrl) {
    console.error('Nenhum ID válido disponível para conectar')
    return
  }

  // Limpeza de qualquer conexão anterior ou tentativa de reconexão
  cleanup()

  // Criar nova conexão
  socket.value = new WebSocket(wsUrl)

  socket.value.onopen = () => {
    connectionStatus.value = 'connected'
    console.log('WebSocket conectado:', wsUrl)

    // Se for admin, buscar usuários ativos
    if (isAdmin.value) {
      fetchActiveUsers()
    }

    // Iniciar heartbeat
    startHeartbeat()
  }

  socket.value.onclose = event => {
    if (connectionStatus.value !== 'disconnected') {
      connectionStatus.value = 'disconnected'
      console.log('WebSocket desconectado:', event.reason)

      // Tentar reconectar após 5 segundos se não foi um fechamento intencional
      reconnectTimeout = setTimeout(() => {
        if (connectionStatus.value === 'disconnected' && initialized) {
          console.log('Tentando reconectar WebSocket...')
          connect()
        }
      }, 5000)
    }
  }

  socket.value.onerror = error => {
    console.error('Erro no WebSocket:', error)
  }

  socket.value.onmessage = event => {
    const data = JSON.parse(event.data)
    console.log('Mensagem recebida:', data)

    if (isAdmin.value) {
      handleAdminMessage(data)
    } else {
      handleUserMessage(data)
    }
  }
}

// Administrador: Processar mensagens recebidas
const handleAdminMessage = data => {
  if (data.action === 'initial_users') {
    activeUsers.value = data.users
  } else if (
    ['user_login', 'user_logout', 'user_timeout', 'force_logout'].includes(
      data.action
    )
  ) {
    fetchActiveUsers()
  }
}

// Usuário comum: Processar mensagens recebidas
const handleUserMessage = data => {
  if (data.action === 'force_logout') {
    doLogout()
  }
}

// Buscar usuários ativos via HTTP (backup para admin)
const fetchActiveUsers = async () => {
  try {
    const response = await fetch(`${api_url}/users/active_users`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
      }
    })
    const data = await response.json()
    activeUsers.value = data
  } catch (error) {
    console.error('Erro ao buscar usuários ativos:', error)
  }
}

// Heartbeat para manter conexão ativa
const startHeartbeat = () => {
  if (heartbeatInterval) {
    clearInterval(heartbeatInterval)
  }

  console.log('Iniciando heartbeat...')
  console.log('ID da sessão atual:', currentSessionId)

  heartbeatInterval = setInterval(() => {
    if (socket.value && socket.value.readyState === WebSocket.OPEN) {
      socket.value.send(JSON.stringify({ type: 'heartbeat' }))

      fetch(`${api_url}/users/heartbeat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        },
        body: JSON.stringify({ session_id: currentSessionId })
      }).catch(err => console.error('Erro ao enviar heartbeat HTTP:', err))
    }
  }, 30000) // A cada 30 segundos
}

// Logout do usuário
const doLogout = async () => {
  try {
    await fetch(`${api_url}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
      },
      body: JSON.stringify({ session_id: currentSessionId })
    })
  } catch (error) {
    console.error('Erro ao fazer logout via HTTP:', error)
  } finally {
    localStorage.clear()
    sessionStorage.clear()
    disconnect()
    window.location.href = '/login'
  }
}

// Limpar recursos
const cleanup = () => {
  if (socket.value) {
    socket.value.close()
    socket.value = null
  }

  if (heartbeatInterval) {
    clearInterval(heartbeatInterval)
    heartbeatInterval = null
  }

  if (reconnectTimeout) {
    clearTimeout(reconnectTimeout)
    reconnectTimeout = null
  }
}

// Desconectar explicitamente
const disconnect = () => {
  initialized = false
  connectionStatus.value = 'disconnected'
  cleanup()
}

// Verificar se o WebSocket está conectado
const isConnected = () => {
  return socket.value && socket.value.readyState === WebSocket.OPEN
}

// Expor serviço WebSocket
export const websocketService = {
  setupWebSocket,
  disconnect,
  isConnected,
  connectionStatus,
  activeUsers,
  forceLogout: doLogout
}
