import { useAuthenticationStore, useGroup, usePermissions } from '@/store'

const setStoreData = function (data) {
  const authStore = useAuthenticationStore()
  const groups = useGroup()
  if (data.access_token != null) {
    authStore.setToken(data.access_token)
    authStore.setRefreshToken(data?.refresh_token || '')
    authStore.setExpires(data?.expires || 0)
  }
  if (data.user) {
    authStore.setUserData(data.user)
    authStore.setUserId(data.user.id)
  }
  authStore.setUserAuth(true)
  if (data.empresa) {
    groups.setEmpresa(data.empresa)
  }
  if (data.grupo) {
    groups.setGroup(data.grupo)
  }
  if (data.grupos) {
    groups.setGroups(data.grupos)
  }
  if (data.permissions) {
    const permissions = usePermissions()
    permissions.setPermissions(data.permissions)
  }
  if (data.session_id) {
    authStore.setSessionId(data.session_id)
  }
}

export default setStoreData
