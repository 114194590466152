import { createBaseService } from '@/services/baseService'

const useAuthenticationService = () => {
  const baseUrl = '/'
  const service = createBaseService(baseUrl)

  return {
    loading: service.loading,
    error: service.error,
    async loginUser(username, password, options = { showToast: true }) {
      return await service.post(
        'login',
        { username, password },
        {
          ...options,
          errorMessage: 'Falha ao fazer login'
        }
      )
    },
    async loginMFA(tempToken, code, options = { showToast: true }) {
      return await service.post(
        'login/mfa',
        { tempToken, code },
        {
          ...options,
          errorMessage:
            'Falha ao fazer login. Verifique o código de autenticação'
        }
      )
    },
    async getUserInformation(user, options = { showToast: true }) {
      return await service.get('users/usuarios/informacoes/' + user, {
        ...options,
        errorMessage: 'Falha ao obter informações do usuário'
      })
    },
    async logout(username, session_id, options = { showToast: false }) {
      return await service.post(
        'logout',
        { email: username, session_id },
        {
          ...options,
          errorMessage: 'Falha ao fazer logout'
        }
      )
    }
  }
}

export default useAuthenticationService
