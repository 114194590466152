import { createBaseService } from '@/services/baseService'

const BASE_URL = '/users/usuarios'
const service = createBaseService(BASE_URL)

export const useUsuarioService = () => {
  return {
    loading: service.loading,
    error: service.error,

    async getUsuarios(options = { showToast: true }) {
      return await service.get('', {
        ...options,
        errorMessage: 'Falha ao buscar usuarios'
      })
    },

    async getUsuariosPaginado(page, limit, options = { showToast: true }) {
      return await service.get(`?page=${page}&limit=${limit}`, {
        ...options,
        errorMessage: 'Falha ao buscar usuarios'
      })
    },

    async getUsuariosFilter(page, limit, filter, key, order, options = { showToast: true }) {
      return await service.get(`/filter?page=${page}&limit=${limit}&search=${filter}&orderby=${key}&order=${order}`, {
        ...options,
        errorMessage: 'Falha ao buscar usuarios'
      })
    },

    async getUsuariosById(id, options = { showToast: true }) {
      return await service.get(`/${id}`, {
        ...options,
        errorMessage: 'Falha ao buscar usuarios'
      })
    },

    async addUsuarios(usuarios, options = { showToast: true }) {
      return await service.post('', usuarios, {
        ...options,
        errorMessage: 'Falha ao adicionar usuarios'
      })
    },

    async updateUsuarios(usuarios, options = { showToast: true }) {
      return await service.put(`/${usuarios.id}`, usuarios, {
        ...options,
        errorMessage: 'Falha ao atualizar usuarios'
      })
    },

    async deleteUsuarios(id, options = { showToast: true }) {
      return await service.delete(`/${id}`, {
        ...options,
        errorMessage: 'Falha ao deletar usuarios'
      })
    },

    async setNewPassword(id, senha, options = { showToast: true }) {
      return await service.post('/update-password', { user_id: id, senha }, {
        ...options,
        errorMessage: 'Falha ao atualizar a senha'
      })
    },

    async addOrUpdateUsuarios(usuarios, options = { showToast: true }) {
      console.log('usuarios', usuarios)
      return await service.post('/add-or-update', usuarios, {
        ...options,
        errorMessage: 'Falha ao adicionar ou atualizar usuarios'
      })
    }
  }
}
