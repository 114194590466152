<template>
  <div :class="{ 'content-hide-menu': !showMenu }" class="content bg-[#1F2937]">
    <aside :class="{
      'hide-menu': !showMenu,
      'animate__animated animate__fadeInLeft': showMenu,
      'animate__animated animate__fadeOutLeft': !showMenu
    }" class="nav-bar bg-[#1F2937]">
      <nav class="w-[100%]">
        <MainMenu />
      </nav>
    </aside>
    <div class="flex justify-between horizontal-info">
      <div class="w-[10%]">
        <HorizontalInfo @toggleMenu="toggleMenu" />
      </div>
      <div class="flex w-[90%] justify-between">
        <!-- Se usuário não aceito os termos, então exibe mensagem simples informando essa pendência -->
        <div class="w-[50%]">
          <div>
          </div>
          <div v-if="!user.aceitoutermos" class="flex items-center">
            <div class="text-sm text-red-500">
              <button @click="showTerms = !showTerms">
                Termos de uso não aceitos
              </button>
            </div>
          </div>
        </div>
        <div class="flex w-[80%] xl:w-[50%] 2xl:w-[30%] h-8 justify-center items-center">
          <div class="bg-athens-gray-800 p-4 text-white font-sans font-medium w-full text-sm h-[50px]">
            {{ grupos.apelido }}
          </div>
          <div class="ml-2">
            <div class="flex items-center justify-center z-50">
              <div class="mt-60 mx-1 sm:mt-1 hs-dropdown relative sm:inline-flex [--auto-close:false] z-50"
                data-auto-close="false">
                <button id="hs-dropdown-auto-close-false"
                  class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                  type="button">
                  <img :src="userAvatar" alt="Image Description"
                    class="inline-block h-8 w-8 rounded-full ring-2 ring-white dark:ring-gray-800" />
                  <span class="hidden sm:inline-block">{{
                    user.nome.split(' ')[0]
                    }}
                  </span>
                  <svg class="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600" fill="none" height="16"
                    viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                      stroke="currentColor" stroke-linecap="round" stroke-width="2" />
                  </svg>
                </button>

                <div aria-labelledby="hs-dropdown-auto-close-false"
                  class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-gray-800 dark:border dark:border-gray-700">
                  <div class="w-full mb-2.5 justify-between space-x-6">
                    <span class="text-[12px]">Habilitar MFA</span>
                    <input id="hs-basic-usage" v-model="userMFAEnable"
                      class="relative w-[3.25rem] h-7 bg-gray-100 checked:bg-none checked:bg-blue-600 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 border ring-1 ring-transparent focus:border-blue-600 focus:ring-blue-600 ring-offset-white focus:outline-none appearance-none dark:bg-gray-700 dark:checked:bg-blue-600 dark:focus:ring-offset-gray-800 before:inline-block before:w-6 before:h-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400 dark:checked:before:bg-blue-200"
                      type="checkbox" @change="onChangeMFAStatus" />
                    <label class="sr-only" for="hs-basic-usage">MFA</label>
                  </div>
                  <div class="flex justify-center items-center">
                    <div v-if="userMFAEnable">
                      <img :src="imageB64" alt="QRCode" class="w-32" />
                    </div>
                  </div>

                  <!-- Alterar senha  -->
                  <div class="py-2 first:pt-0 last:pb-0">
                    <button class="bg-green-50 w-full" @click="openModalAlterarSenha">
                      Alterar Senha
                    </button>
                  </div>

                  <div class="w-full py-2 first:pt-0 last:pb-0">
                    <button
                      class="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-red-300 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                      @click.prevent="doLogout">
                      Sair
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-body">
      <div class="main-body__content">
        <slot />
      </div>
    </div>
  </div>


  <!-- Criar modal termos de uso e permitir que seja aceito neste momento -->
  <div>
    <ModalTermsOfUse :show="showTerms" @accept="setTerms" @close="closeModal" />
  </div>




  <div v-if="updatePassword" @keydown="e => e.key === 'Escape' && (updatePassword = false)"
    class="w-full h-full absolute top-0 left-0 flex items-center justify-center">
    <div class="bg-black backdrop-blur-md opacity-50 absolute inset-0 shadow-lg z-50"></div>
    <div class="w-[400px] h-[330px] bg-white opacity-100 relative border-2 z-50">
      <div>
        <div class="p-4 text-black font-bold shadow-md bg-gray-100 rounded-t-md">
          <p>Alterar senha</p>
        </div>
        <div class="pl-4 mt-2" v-if="loading">
          <Waiting :loading="loading" classSize="w-6 h-6" bgColor="bg-white" messageColor="text-black"
            message="Aguarde, atualizando senha" />
        </div>
        <div class="p-4">

          <label for="password" class="block mb-2 text-sm text-gray-700 font-semibold">Senha</label>
          <div class="relative w-full mb-4">
            <input id="password" v-model="newPassword" :type="showPassword ? 'text' : 'password'"
              class="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200" />
            <button type="button" @click="showPassword = !showPassword"
              class="absolute inset-y-0 right-0 px-3 flex items-center text-sm leading-5 text-gray-500 hover:text-gray-700 transition duration-200">
              <span v-if="showPassword">
                <i class="fa-solid fa-eye"></i>
              </span>
              <span v-else>
                <i class="fa-solid fa-eye-slash"></i>
              </span>
            </button>
          </div>

          <label for="confirmPassword" class="block mb-2 text-sm text-gray-700 font-semibold">Confirmar Senha</label>
          <div class="relative w-full">
            <input id="confirmPassword" v-model="confirmNewPassword" :type="showConfirmPassword ? 'text' : 'password'"
              class="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200" />
            <button type="button" @click="showConfirmPassword = !showConfirmPassword"
              class="absolute inset-y-0 right-0 px-3 flex items-center text-sm leading-5 text-gray-500 hover:text-gray-700 transition duration-200">
              <span v-if="showConfirmPassword">
                <i class="fa-solid fa-eye"></i>
              </span>
              <span v-else>
                <i class="fa-solid fa-eye-slash"></i>
              </span>
            </button>
          </div>

        </div>
        <div class="flex items-center w-full p-4 justify-end">
          <button class="btn-primary" @click="updateUserPassword" :disabled="loading">Salvar</button>
          <button class="btn-danger  ml-2" @click="updatePassword = false" :disabled="loading">
            Cancelar
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  nextTick
} from 'vue'
import { useAuthenticationStore, useGroup } from '@/store'
import { getService } from '@/services/MFAService'
import { POSITION, TYPE, useToast } from 'vue-toastification'
import { useRouter } from 'vue-router'

import MainMenu from '@/components/mainmenu/MainMenu.vue'
import HorizontalInfo from '@/components/mainmenu/HorizontalInfo.vue'
import Waiting from '@/components/Waiting.vue'
import ModalTermsOfUse from '@/components/Modals/ModalTermsOfUse.vue'
import { useUsuarioService } from '@/services/UsuarioService'
import useAuthenticationService from '@/services/AuthenticationService'

import { useWebSocket } from '@/composables/useWebSocket'

import { getAPI } from '@/api/axios_api'
import { computedAsync } from '@vueuse/core'

import Swal from 'sweetalert2'

export default defineComponent({
  name: 'AppLayoutAdmin',
  components: {
    MainMenu,
    HorizontalInfo,
    ModalTermsOfUse,
    Waiting
  },
  setup() {
    const { logout } = useAuthenticationService()


    const router = useRouter()
    const showMenu = ref(true)
    const authenticationStore = useAuthenticationStore()
    const group = useGroup()

    const sessionID = authenticationStore.session_id
    const user = reactive(authenticationStore.user)
    const showTerms = ref(false)
    const imageB64 = ref('') 

    const { error, loading, setNewPassword } = useUsuarioService()
    const newPassword = ref('')
    const confirmNewPassword = ref('')
    const updatePassword = ref(false)
    const showPassword = ref(false)
    const showConfirmPassword = ref(false)

    const { connectionStatus, activeUsers, forceLogout } = useWebSocket('user', null, sessionID)

    onMounted(async () => {
      if (parseInt(user.mfaenable) === 1) {
        await showQrCode();
      }
    });


    const updateUserPassword = async () => {
      if (newPassword.value !== confirmNewPassword.value) {
        Swal.fire({
          title: 'Erro',
          text: 'As senhas não coincidem',
          icon: 'error'
        })
        return
      }

      if (newPassword.value.length < 6) {
        Swal.fire({
          title: 'Erro',
          text: 'A senha deve ter no mínimo 6 caracteres',
          icon: 'error'
        })
        return
      }

      await setNewPassword(
        authenticationStore.user.id,
        newPassword.value,
        {
          showToast: true,
          showSuccessToast: true,
          successMessage: 'Senha atualizada com sucesso'
        }
      )
      updatePassword.value = false
      newPassword.value = ''
      confirmNewPassword.value = ''
      showPassword.value = false
      showConfirmPassword.value = false
    }

    const messageMFA = ref(
      'MFA não está habilitado para essa conta, habilite clicando no nome botão superior direito. ' +
      'Escaneio o QRCode utilizando um app de MFA no seu celular.'
    )

    const showNotificationAboutMFA = () => {
      const toast = useToast()
      toast(messageMFA.value, {
        position: POSITION.BOTTOM_RIGHT,
        type: TYPE.INFO,
        timeout: 3000,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false
      })
    }

    const onChangeMFAStatus = async event => {
      const userStatus = event.target.checked
      await enableMFA(userStatus, user.email)
      userMFAEnable.value = userStatus
      if (userStatus) {
        await showQrCode()
      }
    }

    const enableMFA = async (status, email) => {
      const mfaService = getService()
      const data = {
        email,
        mfaenable: status
      }
      await mfaService.setMFAStatus(data)
    }

    const showQrCode = async () => {
      const mfaService = getService()
      const encodeparam = encodeURIComponent(user.email)
      const response = await mfaService.getMfaQrcode(encodeparam)
      imageB64.value = `data:image/png;base64, ${response}`
    }

    const userMFAEnable = computed({
      get() {
        return parseInt(user.mfaenable) === 1
      },
      set(value) {
        user.mfaenable = value ? 1 : 0
      }
    })

    const getAwsURL = async (bucket, folder, image) => {
      const response = await getAPI().get(
        `/filemanager/download/${bucket}/${folder}/${image}`
      )
      return response
    }

    const imageUsuario = async image => {
      const response = await getAwsURL('onlyimages', 'usuarios', image)
      return response.data
    }

    const userAvatar = computedAsync(
      async () => {
        const image = await imageUsuario(`${user.foto}.jpg`)
        return image
      },
      { refetch: true }
    )

    const setTerms = async () => {
      const userID = authenticationStore.user.id
      const response = await getAPI().patch('/users/usuarios/aceitoutermos', {
        dados: { id: userID }
      })

      if (response.data.result.aceitoutermos === 's') {
        authenticationStore.user.aceitoutermos = 's'
        showTerms.value = false
      }
    }

    const closeModal = () => {
      showTerms.value = false
    }

    const grupos = group.getActiveGroup

    const toggleMenu = () => {
      showMenu.value = !showMenu.value
    }

    const doLogout = async () => {
      try {
        await logout(authenticationStore.user.email, authenticationStore.session_id, { showToast: true })
      } catch (error) {
        console.error(error)
      } finally {
        authenticationStore.logout()
        localStorage.clear()
        sessionStorage.clear()
        group.setClearAll()
        router.push({ name: 'Login' })
      }
    }

    const openModalAlterarSenha = () => {
      updatePassword.value = true
      nextTick(() => {
        const inputPassword = document.getElementById('newPassword')
        setTimeout(() => {
          inputPassword?.focus()
        }, 100)
      })
    }

    return {
      showMenu,
      user,
      showTerms,
      imageB64,
      messageMFA,
      showNotificationAboutMFA,
      onChangeMFAStatus,
      enableMFA,
      showQrCode,
      userMFAEnable,
      userAvatar,
      setTerms,
      closeModal,
      grupos,
      toggleMenu,
      updatePassword,
      newPassword,
      confirmNewPassword,
      setNewPassword,
      openModalAlterarSenha,
      doLogout,
      connectionStatus,
      updateUserPassword,
      loading,
      error,
      activeUsers,
      forceLogout,
      showPassword,
      showConfirmPassword
    }
  }
})
</script>

<style scoped>
.content {
  display: grid;
  grid-template-columns: 240px 12fr;
  grid-template-rows: 1fr 12fr;
  grid-template-areas:
    'nav-bar horizontal-info horizontal-info'
    'nav-bar main-body main-body';
  height: 100vh;
}

.nav-bar {
  grid-area: nav-bar;
  background-color: navbar;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Style Scroolbar */

.nav-bar::-webkit-scrollbar {
  width: 0.5rem;
}

.nav-bar::-webkit-scrollbar-track {
  background: navbar;
}

.nav-bar::-webkit-scrollbar-thumb {
  background: #078ee9;
}

.main-body {
  grid-area: main-body;
  background-color: #f5f5f5;
  padding-left: 1rem;
  padding-right: 1rem;
}

.horizontal-info {
  grid-area: horizontal-info;
  background-color: #ffffff;
  padding: 1rem;
}

.hide-menu {
  display: none;
}

/* Animation when hide menu */

@keyframes hide-menu {
  0% {
    grid-template-columns: 1fr 10fr;
    grid-template-rows: 1fr 10fr;
    grid-template-areas:
      'nav-bar horizontal-info horizontal-info'
      'nav-bar main-body main-body';
  }

  100% {
    grid-template-columns: 1fr 10fr;
    grid-template-rows: 1fr 10fr;
    grid-template-areas:
      'horizontal-info horizontal-info'
      'main-body main-body';
  }
}

.content-hide-menu {
  grid-template-columns: 1fr 10fr;
  grid-template-rows: 1fr 10fr;
  grid-template-areas:
    'horizontal-info horizontal-info'
    'main-body main-body';
}

.main-body__content {
  margin-top: 5px;
  /* padding: 1rem; */
  /*
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
   */
}
</style>
