import useApiHandler from '@/api/apiService'
import { useToast } from 'vue-toastification'

export const createBaseService = baseUrl => {
  const { loading, error, sendRequest } = useApiHandler()

  const handleResponse = (response, options = {}) => {
    const {
      showSuccessToast = false,
      successMessage = 'Operação realizada com sucesso!'
    } = options

    const toast = useToast()

    if (!response.success) {
      throw response.error
    }

    if (showSuccessToast) {
      toast.success(successMessage)
    }

    return response.data
  }

  return {
    loading,
    error,
    async get(endpoint, options = {}) {
      const response = await sendRequest(
        'GET',
        `${baseUrl}${endpoint}`,
        null,
        options
      )
      return handleResponse(response, options)
    },
    async post(endpoint, data, options = {}) {
      const response = await sendRequest(
        'POST',
        `${baseUrl}${endpoint}`,
        data,
        options
      )
      return handleResponse(response, options)
    },
    async put(endpoint, data, options = {}) {
      const response = await sendRequest(
        'PUT',
        `${baseUrl}${endpoint}`,
        data,
        options
      )
      return handleResponse(response, options)
    },
    async delete(endpoint, options = {}) {
      const response = await sendRequest(
        'DELETE',
        `${baseUrl}${endpoint}`,
        null,
        options
      )
      return handleResponse(response, options)
    }
  }
}
